// Import Styles
import '../sass/styles.scss';

// Import Plugins
import 'bootstrap';
import 'jquery-match-height';

import './slider/post-latest';
import './slider/post-related';
import './slider/poker-featured';
import './slider/poker-article-category';
import './slider/team-pros-posts';

// Import JS Class
import { Helpers } from './helpers/helpers';
import { LoadMore } from './ajax/load-more';
import { LoadMorePSPC } from './ajax/load-more-pspc';

import './helpers/clock';
import './dropdown/table-of-contents';

(function() {

    const helper = new Helpers();

    // Setup AJAX Load More
    const loadMore = new LoadMore();

    // Setup AJAX Load More PSPC
    const loadMorePSPC = new LoadMorePSPC();

    $(document).ready(function() {
        helper.wrapTitles();

        $('.h-match').matchHeight();


        // Categories

        var categoryNavigation = $('.js-posts-categories');

        $('.js-posts-categories-next').on('click', function() {
            var leftPosition = categoryNavigation.scrollLeft();
            categoryNavigation.animate({
                scrollLeft: leftPosition + 200,
            }, 300);
        });

        $('.js-posts-categories-previous').on('click', function() {
            var leftPosition = categoryNavigation.scrollLeft();
            categoryNavigation.animate({
                scrollLeft: leftPosition - 200,
            }, 300);
        });

        // Navigation

        $('.js-navigation-side__hamburger').on('click', function() {
            $('.js-navigation-side').addClass('active');
        });

        $('.js-navigation-side__close').on('click', function() {
            $('.js-navigation-side').removeClass('active');
        });

        $('.js-collapse__title').on('click', function() {
            $(this).toggleClass('active');
            $(this).next('.js-collapse__body').toggleClass('active');
        });

        // Search Popup

        $('.js-header-bottom--mobile__search').on('click', function() {
            $('.js-search-form').addClass('active');
        });

        $('.js-search-form__close').on('click', function() {
            $('.js-search-form').removeClass('active');
        });

        // Hamburger

        $('.hamburger').on('click', function(e) {
            e.preventDefault();

            $(this).toggleClass('is-active');
            $('.js-navigation-main').toggleClass('is-active');
        });

        // Double Tap to Go

        if (window.matchMedia('(max-width: 991px)').matches) {
            var previousTarget = null;

            $('.menu-item-has-children > a').on('click', function(e) {
                $('.sub-menu').removeClass('js-open');

                if (this === previousTarget) {
                    // Do nothing...
                } else {
                    e.preventDefault();
                    previousTarget = this;
                }

                $(this).next('.sub-menu').toggleClass('js-open');
            });
        }

        // Mobile Scroll

        if (window.matchMedia('(max-width: 991px)').matches) {
            const urlParams = new URLSearchParams(window.location.search);
            const mobileScroll = urlParams.get('scroll');

            $('.js-navigation-main a').each(function() {
                let url = $(this).attr('href').split("?")[0];
                let urlQuery = url + '?scroll=' + mobileScroll;
                $(this).attr('href', urlQuery);
            });
        }

        // Lightbox

        const lightbox = $('.js-video-lightbox');
        const embedContainer = lightbox.find('.embed-container');

        function openLightbox(e) {
            e.preventDefault();
            const button = $(this);
            const videoId = button.data('video-id');
            const accountId = button.data('account-id') || '2637383244001';
            const locale = button.data('locale') || 'en';

            const videoHtml = `
                <video class="video-js" data-video-id="${videoId}" data-account="${accountId}" data-player="HkGNBnrbiW" data-embed="default" data-application-id="" controls language="${locale}" translate></video>
                <script src="//players.brightcove.net/${accountId}/HkGNBnrbiW_default/index.min.js"></script>
            `;

            embedContainer.html(videoHtml);
            lightbox.addClass('active');
        }

        function closeLightbox(e) {
            e.preventDefault();
            embedContainer.find('video').trigger('pause');
            lightbox.removeClass('active');
            embedContainer.empty();
        }

        $(document).on('click', '.js-video-lightbox-button', openLightbox);
        $(document).on('keypress', '.js-video-lightbox-button', function(e) {
            if (e.key === 'Enter') {
                openLightbox.call(this, e);
            }
        });

        $(document).on('click', '.js-video-lightbox-close', closeLightbox);
        $(document).on('keypress', '.js-video-lightbox-close', function(e) {
            if (e.key === 'Enter') {
                closeLightbox.call(this, e);
            }
        });

        // Scroll Navigation

        $('.c-posts-categories').scroll(function() {

            let scrollLeft, menuWidth;

            scrollLeft = $(this).scrollLeft();
            menuWidth = $(this)[0].scrollWidth;

            if (scrollLeft > 0) {
                $(this).addClass('scrolled');
            } else {
                $(this).removeClass('scrolled');
            }
        });

        // Back to Top

        $(window).on('scroll', function() {
            if ($(this).scrollTop() > 200) {
                $('.js-back-to-top').fadeIn(200);
            } else {
                $('.js-back-to-top').fadeOut(200);
            }
        });

        $('.js-back-to-top').on('click', function() {
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            return false;
        });
    });

    $(window).scroll(function() {

        let topHeaderHeight, middleHeaderHeight, bottomHeader, bottomHeaderHeight, headerOffset;
        let bottomHeaderTop = 0;

        if ( $(window).width() >= 768 )
        {
            topHeaderHeight = $('.js-header-top').outerHeight();
            middleHeaderHeight = $('.js-header-middle').outerHeight();
            bottomHeader = $('.js-header-bottom');
            bottomHeaderHeight = $('.js-header-bottom').outerHeight();

            headerOffset = topHeaderHeight + middleHeaderHeight;
        }
        else
        {
            topHeaderHeight = $('.js-header-top--mobile').outerHeight();
            middleHeaderHeight = $('.js-header-middle--mobile').outerHeight();
            bottomHeader = $('.js-header-bottom--mobile');
            bottomHeaderHeight = $('.js-header-bottom--mobile').outerHeight();
            

            headerOffset = topHeaderHeight + middleHeaderHeight;
        }

        // If we have a legal notice
        if ( $('.js-notice-legal').length )
        {
            bottomHeaderTop = bottomHeaderTop + $('.js-notice-legal').outerHeight();
        }

        // If we have a legal notice
        if ( $('.c-notice-slider').length )
        {
            headerOffset = headerOffset + $('.c-notice-slider').outerHeight();
        }

        let siteContent = $('.js-site-content');

        if ( $(window).scrollTop() >= headerOffset )
        {
            bottomHeader.css('position', 'fixed');
            bottomHeader.css('top', bottomHeaderTop);
            siteContent.css('margin-top', bottomHeaderHeight);
        }
        else
        {
            bottomHeader.css('position', 'relative');
            bottomHeader.css('top', 0);
            siteContent.css('margin-top', 0);
        }

    });

    $('.js-navigation-main').scroll(function() {

        let scrollLeft, menuWidth;

        scrollLeft = $(this).scrollLeft();
        menuWidth = $(this)[0].scrollWidth;

        if (scrollLeft > 0) {
            $(this).addClass('scrolled');
        } else {
            $(this).removeClass('scrolled');
        }

        if (scrollLeft >= menuWidth - $(window).width()) {
            $('.js-header-bottom--mobile__search').addClass('scrolled');
        } else {
            $('.js-header-bottom--mobile__search').removeClass('scrolled');
        }

        // Mobile Scroll

        if (window.matchMedia('(max-width: 991px)').matches) {
            $('.js-navigation-main a').each(function() {
                let url = $(this).attr('href').split("?")[0];
                let urlQuery = url + '?scroll=' + scrollLeft;
                $(this).attr('href', urlQuery);
            });
        }
    });

})();